export default class BrockmanEdmonds {
  constructor(args) {
    this.selector = args.selector || '.edmonds-block:not([data-init]';
    this.endpoint = args.edmonds_url || 'https://edmonds.gamer-network.net';
    this.display_zeros = args.display_zeros || false;
    this.clean_limit = args.clean_limit || 7;

    this.options = {
      site: args.site,
      product_id: '',
      category: '',
      campaign: '',
      department: '',
      style: 'grid',
      search: '',
      limit: '',
      order: '',
      country: '',
      affiliate_tag_group: '',
      label: 'Related Products',
      video_game_tags: '',
      video_game_platform_tags: '',
      associated_affiliate: '',
    };

    this.loc = args.loc || {
      buy_now: 'Buy now',
      label: 'Related products',
    };
  }

  run() {
    const batch = [];
    const containers = document.querySelectorAll(this.selector);
    if (!containers.length) return;

    containers.forEach((el) => {
      const data = JSON.parse(JSON.stringify(el.dataset));

      Object.keys(this.options).forEach((k) => {
        if (!(k in data) && this.options[k]) {
          data[k] = this.options[k];
        }
      });
      batch.push(data);
    });

    this.batchAffiliatesData(batch, containers);
  }

  batchAffiliatesData(batch, containers) {
    if (!batch.length) return;

    const clean = [];
    let cleanKey = 0;

    batch.forEach((set, i) => {
      // Split up batch into groups to stop request from getting too big
      if (!(i % this.clean_limit)) {
        if (i > 0) cleanKey += 1;
        clean[cleanKey] = {
          start: i,
          set: [],
        };
      }

      if (Array.isArray(set.label)) {
        set.label = set.label[Math.floor(Math.random() * set.label.length)];
      }

      Object.keys(set).forEach((k) => {
        if (set[k] === null || set[k] === undefined || set[k] === '') {
          delete set[k];
        }
      });

      clean[cleanKey].set.push(set);
    });

    for (let i = 0; i < clean.length; i += 1) {
      const data = clean[i];
      // Set AJAX call as a function to prevent `data` from being overwritten
      // on iterations while waiting for AJAX response
      const callAPI = this.callAPI.bind(this);
      callAPI(data, containers);
    }
  }

  callAPI(data, containers) {
    const labels = {};

    Object.keys(data.set).forEach((k) => {
      labels[k] = data.set[k].label;
      delete data.set[k].label;
    });

    const json = JSON.stringify(data.set);
    const url = `${
      this.endpoint
    }/api/v1/batch-affiliate-links/?batch=${encodeURIComponent(json)}`;

    const req = new XMLHttpRequest();
    req.open('GET', url);
    req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    req.onreadystatechange = () => {
      if (req.readyState !== XMLHttpRequest.DONE) return;
      const resp = JSON.parse(req.responseText);
      const { batches } = resp;
      let i = 0;
      Object.keys(batches).forEach((k) => {
        const batch = batches[k];
        const key = data.start + i;
        const set = data.set[i];
        set.label = labels[i];
        this.render(containers[key], batch, set);
        i += 1;
      });
    };
    req.send();
  }

  render(container, data, options) {
    if (!data.products || !data.products.length) return;
    if (data.products[0].associated_affiliate_product)
      this.renderAssociatedAffiliate(container, data, options);
    else this.renderAffiliate(container, data, options);
  }

  renderAffiliate(container, data, options) {
    const wrapper = document.createElement('div');
    wrapper.classList = `wrapper ${options.style} ${options.site
      .replace(/\./g, '-')
      .replace(/_/g, '-')}`;

    if (data.products.length > 1) {
      wrapper.classList.add('multiple');
      container.classList.add(`multiple-${options.style}`);
    } else {
      container.classList.add(`single-${options.style}`);
    }

    wrapper.dataset.count = data.products.length;

    let label = '';
    if (options.label) {
      label = `<p class="label">${options.label}</p>`;
    }

    let productsMarkup = '';
    data.products.forEach((el) => {
      const partnerClass = el.partner.domain
        .replace(/\./g, '-')
        .replace(/_/g, '-');
      productsMarkup += this.getProductMarkup(
        el.link,
        el.name,
        el.image,
        el.prices,
        partnerClass,
      );
    });

    wrapper.innerHTML = `
      ${label}
      <div class="products">
        ${productsMarkup}
      </div>
    `;

    container.append(wrapper);
    container.dataset.init = true;
  }

  renderAssociatedAffiliate(container, data) {
    const affiliateProduct = data.products[0];
    container.innerHTML = `
    <div class='${affiliateProduct.partner.name} associated-affiliate-product'>
      <a href="${affiliateProduct.link}" class="affiliate-product-wrapper">
        <div class="affiliate-details">
          <div class="product-row">
            <span style="color: #${affiliateProduct.partner.colour};">${
              affiliateProduct.name
            }</span>
          </div>
          <div class="affiliate-price-row">
            <img src="${affiliateProduct.partner.logo}"></img>
            <span style="color: #${
              affiliateProduct.partner.colour
            };">${this.getPrices(affiliateProduct.prices)}</span>
          </div>
        </div>
        <div class="affiliate-product-image">
          <img src='${affiliateProduct.image}'></img>
        </div>
      </a>
    </div>`;
  }

  getLinkAttributes(link) {
    return `href="${link}" target="_blank" rel="nofollow noopener" data-event-source="edmonds"`;
  }

  getProductMarkup(link, name, image, prices, partnerClass) {
    if (link.match(/amazon\./i) && link.indexOf('ascsubtag') === -1) {
      let subtag = '';

      if (window.location.pathname.length > 1) {
        subtag = window.location.pathname.substring(1);
        subtag = subtag.replace(/\//g, '-').substring(0, 89);
      } else {
        subtag = 'index';
      }

      link +=
        (link.indexOf('?') === -1 ? '?ascsubtag=' : '&ascsubtag=') + subtag;
    }

    return `
      <div class="product">
        <span class="image" style="background-image: url(${image})"></span>

        <div class="details">
          <span class="name">
            <a class="link link--expand link--invert" ${this.getLinkAttributes(
              link,
            )}>${name}</a>
          </span>

          ${this.getPrices(prices)}

          <a
            class="button small"
            data-partner="${partnerClass}"
            ${this.getLinkAttributes(link)}
          >
            ${this.loc.buy_now}
          </a>
        </div>
      </div>
    `;
  }

  getPrices(prices) {
    let priceMarkup = '';

    Object.keys(prices).forEach((k) => {
      const currencySymbol = this.getCurrencySymbol(k);

      if (!this.display_zeros) {
        prices[k].current_price = prices[k].current_price.replace(/\.00$/, '');
        prices[k].original_price = prices[k].original_price.replace(
          /\.00$/,
          '',
        );
      }

      if (prices[k].current_price < prices[k].original_price) {
        priceMarkup += `<div class="price">${currencySymbol}${prices[k].current_price}<span class="original_price">${currencySymbol}${prices[k].original_price}</span></div>`;
      } else {
        priceMarkup += `<div class="price">${currencySymbol}${prices[k].current_price}</div>`;
      }
    });

    return priceMarkup;
  }

  getCurrencySymbol(currencyCode) {
    const currencies = {
      GBP: '&pound;', // British Pounds
      USD: '&dollar;', // US dollars
      CAD: 'CAD&dollar;', // Canadian dollars
      AUD: 'AUD&dollar;', // Australian dollars
      NZD: 'NZD&dollar;', // New Zealand dollars
      EUR: '&euro;', // Euros
      JPY: '&yen;', // Japanese Yen
      INR: '&#8377;', // Indian Rupees
    };

    const currency = currencies[currencyCode];

    if (typeof currency !== 'undefined') return currency;
    return currencyCode;
  }
}
