export default class BrockmanRecommendations {
  constructor(args) {
    this.data = args.data || [];
    this.selector = args.selector || '.injection_placeholder';
    this.loc = args.loc || {
      label: 'Related',
    };
  }

  run() {
    this.data.forEach((data) => this.generateRecommendation(data));
  }

  generateRecommendation(data) {
    if (!data) return;

    const placeholder = document.querySelector(this.selector);
    if (!placeholder) return;

    const { url, title, strapline, thumbnail } = data;
    if (!url || !title || !strapline || !thumbnail) return;

    const { position } = placeholder.dataset;
    const aside = document.createElement('aside');
    aside.classList = 'recommendation';
    aside.dataset.component = 'recommendation';
    aside.dataset.position = position;
    aside.innerHTML = `
      <span class="recommendation__badge">${this.loc.label}</span>
      <img class="recommendation__thumbnail" src="${thumbnail}" loading="lazy" alt="" width="16" height="9">
      <div class="recommendation__details">
        <a href="${url}" class="recommendation__title link link--expand link--invert" data-recommendation="true">${title}</a>
        <div class="recommendation__strapline">${strapline}</div>
      </div>
    `;

    placeholder.replaceWith(aside);
  }
}
